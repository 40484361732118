(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-virtualpage/assets/javascripts/virtual.js') >= 0) return;  svs.modules.push('/components/components/analytics-virtualpage/assets/javascripts/virtual.js');

svs.components = svs.components || {};
svs.components.analytics = svs.components.analytics || {};
svs.core = svs.core || {};
svs.core.analytics = svs.core.analytics || {};
svs.core.analytics.adl = svs.core.analytics.adl || {};

svs.components.analytics.trackVirtual = function(subUrl, optTitle, options) {
  var dataLayerData = {
    event: 'pageview',
    page: {
      pageInfo: {
        pageID: document.location.pathname,
        sysEnv: svs.core.analytics.data.svsanalytics.formFactor
      }
    }
  };
  var url = location.pathname;

  if (url.charAt(url.length - 1) === '/') {
    url = url.slice(0, -1);
  }
  if (subUrl) {
    url = url + '/' + subUrl;
  }
  if (location.search) {
    url = url + location.search;
  }
  var metric = new svs.core.log.Message(svs.core.log.MessageType.pageview);
  dataLayerData.page.pageInfo.destinationURL = url;
  metric.destPage = url;

  if (optTitle) {
    dataLayerData.page.pageInfo.pageName = optTitle;
    metric.pageTitle = optTitle;
  } else {
    dataLayerData.page.pageInfo.pageName = document.title;
  }
  if (options && options.isModal) {
    metric.isModal = options.isModal;
    dataLayerData.page.pageInfo.isModal = options.isModal;
  }

  svs.core.log.track('svs.components.analytics', metric);
  svs.core.analytics.adl.page = {
    name: document.location.pathname.substring(1).replace(/\//g, ':'),
    url: location.pathname + location.search
  };
  window.adobeDataLayer.adlPush('pageView', {
    site: svs.core.analytics.adl.site,
    consent: svs.core.analytics.adl.consent,
    user: svs.core.analytics.adl.user,
    page: svs.core.analytics.adl.page
  });
  dataLayerPush(dataLayerData);
};


 })(window);